// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { config } from "config";

export const environment = {
  production: false,

  // baseUrl : 'http://localhost:5000/powerswap-1bf48/asia-east2/',
  baseUrl: 'https://asia-east2-powerswap-1bf48.cloudfunctions.net/',
  // baseUrl1 : 'http://34.93.152.228:3000/',
  baseUrl1: 'http://52.21.67.174:5000/',
  // baseUrl1: 'http://34.93.152.228:5000/',

  baseUrl2: 'http://localhost:5000/powerswap-1bf48/asia-east2/',



  apiEndPoint1: 'http://localhost:5000/powerswap-1bf48/asia-east2/',


  apiEndPoint: 'https://asia-east2-powerswap-1bf48.cloudfunctions.net/',

  countryId: "cCpfYVUD0bK7xsCkGHas",
  country: "India",
  countryCode: "IN",

  passwordRegEx: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
};

import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError} from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppLoaderService } from "../services/app-loader/app-loader.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private jwtAuth: JwtAuthService,
    private loader: AppLoaderService,   
    private snack: MatSnackBar) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();

    var changedReq;
    if (req.headers.get("skip")) {
      changedReq = req;
    } else if (token) {
      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
      });

    } else {

      changedReq = req; 
    }
    return next.handle(changedReq).pipe(
      finalize(() =>{ this.loader.close();}),
      catchError((err) => {
        this.loader.close();
        if (err && err.status == 440) {

          this.snack.open("Please login", null,
          { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center' });
          this.jwtAuth.clearStorage();
          this.loader.getDialog().closeAll();

        }else if (err && err.status == 401 || err.status == 403) {

            // this.jwtAuth.checkRefreshToken();
            this.jwtAuth.clearStorage();
            this.loader.getDialog().closeAll();
        
        }else if (err && err.status == 500) {

              this.jwtAuth.clearStorage();
              this.loader.getDialog().closeAll();
              return throwError(err);
            
        }
        
        else
          
        return throwError(err);
          this.loader.close();
        }  
      )
      )
  };
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Action } from "../models/action.model";

interface IMenuItem
{
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  actions?: Action[];
}
interface IChildItem
{
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  actions?: Action[];
  disabled?: boolean;
}

interface IBadge
{
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService
{
  adminMenu: IMenuItem[];

  iconMenu: IMenuItem[] = [
    {
      "name": "DASHBOARD",
      "type": "link",
      "tooltip": "Dashboard",
      "icon": "dashboard",
      "state": "dashboard"
    },
    {
      "name": "Battery Data Analysis Report",
      "type": "link",
      "tooltip": "Battery Data Analysis Report",
      //"icon": "",
      "state": "battery-data-analysis-report"
    },

    {
      "name": "User Management",
      "type": "dropDown",
      "tooltip": "User Management",
      "icon": "people",
      "state": "user-management",
      "sub": [
        {
          "name": "Add User",
          "state": "add-user",
        }
      ]
    },
    {
      "name": "Battery Excel Download",
      "type": "dropDown",
      "tooltip": "Battery Excel Download",
      "icon": "assignment",
      "state": "battery-excel-download",
      "sub": [
        {
          "name": "Download File",
          "state": "download-file",
        }
      ]
    },
    {
      "name": "BMS Management",
      "type": "dropDown",
      "tooltip": "BMS Management",
      "icon": "battery_charging_full",
      "state": "bms-management",
      "sub": [
        {
          "name": "Add Battery",
          "state": "add-battery",
        },
        {
          "name": "Add Charging Stations",
          "state": "add-charging-stations",
        },
        {
          "name": "Bms Sota Logs",
          "state": "bms-sota-logs",
        },
        {
          "name": "Add Station Operator",
          "state": "add-station-operator",
        },
        {
          "name": "Bms Sota File Upload",
          "state": "bms-sota-file-upload",
        },
       
        {
          "name": "Tele Fota File Upload",
          "state": "tele-fota-file-upload",
        },
        {
          "name": "Add Battery Charging Station",
          "state": "add-battery-charging-station",
        },
        {
          "name": "Add Admin",
          "state": "add-admin",
        },
        {
          'name': 'Device Configuration',
          'state': 'device-configuration'
        }
      ]
    },
    {
      "name": "Services Plan",
      "type": "link",
      "tooltip": "Services Plan",
      "icon": "settings",
      "state": "services-plan",
    },
    {
      "name": "Dashboard",
      "type": "link",
      "tooltip": "Dashboard",
      "icon": "dashboard",
      "state": "customerDashboard"
    },
    {
      "name": "Transaction Report",
      "type": "dropDown",
      "tooltip": "Transaction Report",
      "icon": "description",
      "state": "transaction-report",
      "sub": [
        {
          "name": "Battery & Slot Usage Report",
          "state": "battery-slot-usage-report",
        },
        {
          "name": "Swap Station Usage Report",
          "state": "swap-station-usage-report",
        }
      ]
    },
  ]
  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>( this.iconMenu );
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor () { }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.

  publishNavigationMenu ( menu: IMenuItem[] )
  {
    this.menuItems.next( menu );
  }

  // publishNavigationChange(menuType: string) {
  //   this.menuItems.next(this.iconMenu);
  // }
}
